<template>
  <div>
    <b-row>
      <b-table :items="socialLinks" :fields="fields">
        <template #cell(actions)="row">
          <span class="d-inline-block text-danger text-truncate" style="max-width: 4rem;">
            <a class="text-success" @click="editSocialLink(row.item, row.index)"><i class="feather icon-edit-1"></i></a>
            &nbsp;
            <a class="text-danger" @click="deleteSocialLink(row.index)"><i class="feather icon-trash"></i></a>
          </span>
        </template>
        <template #cell(label)="row">
          <span class="d-inline-block text-danger text-truncate" style="max-width: 4rem;">
            <a @click="editSocialLink(row.item, row.index)">{{ row.value }}</a>
          </span>
        </template>
        <template #cell(url)="row">
          <a target="_blank" :href="row.item.url">
            <span class="user-select-none d-inline-block text-truncate" style="max-width: 20rem;">
              <feather-icon icon="ExternalLinkIcon" class="text-light" />
              {{ row.item.url }}
            </span>
          </a>
        </template>
      </b-table>
    </b-row>

    <b-modal
      id="modal-center"
      v-model="openEditModal"
      centered
      size="lg"
      :title-html="modalTitle"
      ok-only
      @ok="closeModal"
      :ok-title="'Save Social Link'"
    >
      <b-card-text>
        <student-edit-tab-social-links ref="crudEditSocialLinks" :link="selectedLink" @close-modal="closeModalImmediate" :student-data="studentData" :profile-data="profileData"/>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BTab,
  BTabs,
  BCol,
  BLink,
  BBadge,
  BRow,
  BCardText,
  BModal,
  VBTooltipPlugin,
} from "bootstrap-vue";
import moment from 'moment';
import StudentEditTabSocialLinks from "@/views/apps/student/students-edit/tabs/StudentEditTabSocialLinks.vue";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onUnmounted, ref } from "@vue/composition-api";
import studentStoreModule from '@/views/apps/student/studentStoreModule';

export default {
  name: 'StudentSocialItem',
  components: {
    StudentEditTabSocialLinks,
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BTab,
    BTabs,
    BCol,
    BLink,
    BBadge,
    BRow,
    BModal,
    BCardText,
  },
  directives: {
    'b-tooltip': VBTooltipPlugin,
  },
  props: {
    studentData: {
      type: Object,
    },
    profileData: {
      type: Object,
    },
    socialLinks: {
      type: Array,
    },
  },
  computed: {
    modalTitle() {
      const subtitle = this.selectedLink && this.selectedLink.link && this.selectedLink.link.label ? (': ' + this.selectedLink.link.label) : '';
      return '<i class=\'feather icon-edit\'></i> Edit Social Link' + subtitle;
    }
  },
  mounted() {
    this.loadedLinks = this.socialLinks;
  },
  data() {
    return {
      loadedLinks: [],
      fields: [
        {
          key: 'label',
          label: 'Label',
          thStyle: { width: '25%' },
        },
        {
          key: 'url',
          label: 'URL',
          thStyle: { width: '70%' },
        },
        {
          key: 'actions',
          label: '',
          thStyle: { width: '5%' },
        },
      ],
      openEditModal: false,
      selectedLink: null,
    };
  },
  methods: {
    closeModalImmediate() {
      this.openEditModal = false;
    },
    closeModal() {
      this.$refs.crudEditSocialLinks.doSaveChanges();
    },
    editSocialLink(link, index) {
      this.selectedLink = { link: JSON.parse(JSON.stringify(link)), index };
      this.openEditModal = true;
    },
    deleteSocialLink(index) {
      const links = this.loadedLinks;
      const link = links[index];

      if(link === undefined) return;

      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to permanently delete this link?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        confirmButtonText: 'Delete',
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loadedLinks.splice(index, 1);

          const parsed = this.loadedLinks.filter((add) => {
            return add.url;
          }).map(cert => {
            return {
              url: cert.url,
              label: cert.label,
            };
          });

          store
            .dispatch('app-student/updateStudentProfile', {
              id: this.studentData.id,
              profileData: { socialLinks: parsed },
            })
            .then((response) => {
              this.reloadSocialLinks();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Social link deleted!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              });
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed to edit student',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.response ? error.response.data.message : error.message,
                },
              });
            });
        }
      });
    },
    reloadSocialLinks() {
      this.$store
        .dispatch('app-student/fetchStudentProfile', {
          id: this.studentData.id,
          // queryParams: {cb: Date.now()}
        })
        .then((response) => {
          const json = response.data;

          this.loadedLinks = json.socialLinks;

          if (!this.loadedLinks) this.loadedLinks = [];
        });
    }
  },
  setup(props) {
    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);
    }

    return {
      moment,
    };
  },
};
</script>

<style lang="scss" scoped>
.attr-tag {
  float: right;
  margin: 2.5px 0 2.5px 5px;
}
</style>
