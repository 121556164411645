<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <div>
        <feather-icon icon="StudentIcon" size="19" />
        <h4 class="mb-0" style="padding-top: 5px;">Social Media Links</h4>
      </div>

      <div class="ml-auto">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-sm" @click="repeatAgain">
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Add New</span>
        </b-button>
      </div>
    </div>
    <hr>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <!-- Form: Personal Info Form -->
      <b-form ref="form" @submit.prevent="repeatAgain">
        <h5 v-if="!unpreloadedLists || unpreloadedLists.length === 0" class="text-center">No social sites listed</h5>

        <!-- Row Loop -->
        <b-row v-else v-for="(item, index) in socialLinks" :id="item.id" :key="item.id" ref="row">
          <!-- Item Name -->
          <b-col lg="12" md="12" sm="12" v-if="item.preloaded === false">
            <div class="row">
              <div class="col-11">
                <b-form-group label="Label" :label-for="`social-label-${index}`">
                  <b-form-input :id="`social-label-${index}`" type="text" placeholder="LinkedIn" v-model="item.label" />
                </b-form-group>
              </div>
              <div class="col-1" style="padding-top: 4px;">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  style="padding-left: 10px; padding-right: 7px; padding-top: 11px; padding-bottom: 11px;"
                  class="mt-0 mt-md-2 btn-sm"
                  @click="removeItem(index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </div>
            </div>
          </b-col>

          <!-- Url -->
          <b-col lg="12" md="12" sm="12" v-if="item.preloaded === false">
            <b-form-group label="URL" :label-for="`social-url-${index}`">
              <b-form-input
                :id="`social-url-${index}`"
                type="url"
                placeholder="https://www.linkedin.com/in/ishan-jadhwani"
                v-model="item.url"
              />
            </b-form-group>
          </b-col>

          <!-- Type -->
          <b-col v-if="false" lg="2" md="2" sm="12">
            <b-form-group label="Type" :label-for="`social-type-${index}`">
              <v-select
                :id="`social-type-${index}`"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="item.type"
                :options="[
                  { label: 'Personal', value: 'personal' },
                  { label: 'School', value: 'school' },
                  { label: 'Work', value: 'work' },
                ]"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (item.type = val)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="item.preloaded === false">
            <hr />
          </b-col>
        </b-row>
      </b-form>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onMounted, onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useStudentsList from '../../students-list/useStudentsList';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import studentStoreModule from '@/views/apps/student/studentStoreModule';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    studentData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    studentData: function () {},
  },
  data: () => {
    return {
      submit: { sync: false },
      socialLinks: [],
      nextTodoId: 1,
    };
  },
  computed: {
    unpreloadedLists() {
      return this.socialLinks.filter(link => link.preloaded === false);
    }
  },
  methods: {
    doSaveChanges() {
      const pwd = this.studentData;
      const id = pwd.id;

      this.socialLinks = this.socialLinks.filter((add) => {
        return add.url;
      }).map(cert => {
        return {
          url: cert.url,
          label: cert.label,
        };
      });

      store
        .dispatch('app-student/updateStudentProfile', {
          id,
          profileData: { socialLinks: this.socialLinks },
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Student Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });

          this.loadProfile();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit student',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },

    repeatAgain() {
      this.socialLinks.push({
        preloaded: false,
      });
    },
    removeItem(index) {
      this.socialLinks.splice(index, 1);
    },
    loadProfile(cb) {
      this.$store.dispatch('app-student/fetchStudentProfile', { id: this.studentDataInfo.id }).then((response) => {
        this.socialLinks = response.data.socialLinks;

        if(typeof cb === 'function') {
          cb();
        }

        console.log(`Sociales registered`, this.socialLinks);
      });
    },
  },
  mounted() {
    this.loadProfile(() => this.repeatAgain());
  },
  setup(props) {
    const studentDataInfo = ref(props.studentData);

    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
        setTimeout(() => {
          store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);
        }, 3000);
      }
    });

    const { statusOptions } = useStudentsList();

    return {
      studentDataInfo,
      statusOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
