<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <div>
        <feather-icon icon="StudentIcon" size="19" />
        <h4 class="mb-0" style="padding-top: 5px;">{{ link.link.label }}</h4>
      </div>

      <div class="ml-auto">
        <a class="btn-sm text-primary btn-block user-select-none" @click="deletePrompt = !deletePrompt">
          <feather-icon icon="XIcon" class="mr-25" />
          <span>Delete?</span>
        </a>

        <div v-show="deletePrompt" class="ml-auto" style="position: absolute; width: 280px; right: 80px; top: 10px; text-align: right; padding-right: 32px;">
          <small>Are you sure? &nbsp;</small>
          <b-button-group size="sm" @click="deletePrompt = false">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-sm" variant="primary" @click="removeItem">
              Yes
            </b-button>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-sm" variant="outline-primary">
              No
            </b-button>
          </b-button-group>
        </div>
      </div>
    </div>
    <hr>

    <!-- Form: Personal Info Form -->
    <b-form ref="form" @submit.prevent="false">
      <!-- Row Loop -->
      <b-row ref="row">
        <!-- Item Name -->
        <b-col lg="12" md="12" sm="12">
          <b-form-group label="Label" :label-for="`social-label-1`">
            <b-form-input :id="`social-label-1`" type="text" placeholder="LinkedIn" v-model="link.link.label" />
          </b-form-group>
        </b-col>

        <!-- Url -->
        <b-col lg="11" md="11" sm="11">
          <b-form-group label="URL" :label-for="`social-url-1`">
            <b-form-input
              :id="`social-url-1`"
              type="url"
              placeholder="https://www.linkedin.com/in/ishan-jadhwani"
              v-model="link.link.url"
            />
          </b-form-group>
        </b-col>
        <b-col lg="1" md="1" sm="1">
          <div style="padding-top: 26px;">
            <a :href="link.link.url" target="_blank" class="btn btn-danger" style="padding: 11px 10px 11px 12px;">
              <i class="feather icon-link"></i>
            </a>
          </div>
        </b-col>

        <!-- Type -->
        <b-col v-if="false" lg="2" md="2" sm="12">
          <b-form-group label="Type" :label-for="`social-type-1`">
            <v-select
              :id="`social-type-1`"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="link.link.type"
              :options="[
                { label: 'Personal', value: 'personal' },
                { label: 'School', value: 'school' },
                { label: 'Work', value: 'work' },
              ]"
              class="w-100"
              :clearable="false"
              :reduce="(val) => val.value"
              @input="(val) => (link.link.type = val)"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="float-left text-muted">
        <small>
          Created at {{ moment(link.link.createdAt).utc().format('L hh:mm A') }}.
        </small>
      </div>
      <div class="float-right text-muted">
        <small v-if="link.link.updatedAt">
          Last updated at {{ moment(link.link.updatedAt).utc().format('L hh:mm A') }}.
        </small>
      </div>
      <div class="clearfix"></div>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BButtonGroup,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onMounted, onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useStudentsList from '../../students-list/useStudentsList';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import studentStoreModule from '@/views/apps/student/studentStoreModule';
import moment from 'moment';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    BButtonGroup,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    studentData: {
      type: Object,
      required: true,
    },
    profileData: {
      type: Object,
      required: true,
    },
    link: {
      type: Object,
      required: true,
    },
  },
  watch: {
    studentData: function () {},
  },
  data: () => {
    return {
      socialLinks: [],
      deletePrompt: false,
    };
  },
  methods: {
    doSaveChanges(cb) {
      const pwd = this.studentData;
      const id = pwd.id;

      const link = JSON.parse(JSON.stringify(this.link.link));

      const copy = JSON.parse(JSON.stringify(this.socialLinks));
      copy[this.link.index] = link;

      const parsed = copy.filter((add) => {
        return add.url;
      }).map(cert => {
        return {
          url: cert.url,
          label: cert.label,
          _id: cert._id,
        };
      });

      store
        .dispatch('app-student/updateStudentProfile', {
          id,
          profileData: { socialLinks: parsed },
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Student Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });

          this.loadProfile();

          if(typeof cb === 'function') {
            cb();
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit student',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },

    removeItem() {
      if(this.socialLinks[this.link.index] === undefined) return;
      this.socialLinks.splice(this.link.index, 1);

      this.doSaveChanges(() => this.$emit('close-modal'));
    },
    loadProfile() {
      store.dispatch('app-student/fetchStudentProfile', { id: this.studentDataInfo.id }).then((response) => {
        this.socialLinks = response.data.socialLinks;

        console.log(`Sociales registered`, this.socialLinks);
      });
    },
  },
  mounted() {
    this.loadProfile();
  },
  setup(props) {
    const studentDataInfo = ref(props.studentData);

    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
        setTimeout(() => {
          store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);
        }, 3000);
      }
    });

    const { statusOptions } = useStudentsList();

    return {
      studentDataInfo,
      statusOptions,
      moment,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
